import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Book } from "../types/book.type";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-coloring-books",
    templateUrl: "./coloring-books.component.html",
    styleUrls: [ "./coloring-books.component.scss" ]
})

export class ColoringBooksComponent implements OnInit {
    public readonly bucketName: string = environment.WEBSITE_BUCKET_NAME;
    public books: Book[] = [];

    public constructor(
        private titleService: Title,
        private metaService: Meta,
        private activatedRoute: ActivatedRoute
    ) {}

    public async ngOnInit() {
        this.activatedRoute.data.subscribe(({ books }) => {
            this.books = books;

            this.titleService.setTitle("Coloring books | Coloring book empire");

            this.metaService.updateTag({
                name: "keywords",
                content: ""
            })

            this.metaService.updateTag({
                name: "description",
                content: ""
            })
        })
    }
}
