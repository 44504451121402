import { API } from "aws-amplify";
import { AuthService } from "./auth.service";
import { ApiCoreResponseError } from "../types/api-core-response-error.type";

export class ApiCoreService {
    protected readonly apiPath: string;
    protected readonly apiName: string;

    private readonly auth: AuthService = new AuthService();

    public constructor(path: string, name: string) {
        this.apiPath = path;
        this.apiName = name;
    }

    protected async init(body: any = null, auth: boolean = true) {
        if (auth) {
            const usr = await this.auth.getAuthenticatedUser()

            return {
                queryStringParameters: {
                    token: usr.signInUserSession.accessToken.jwtToken
                },
                body: body,
                response: true
            }
        }

        return {
            body: body,
            response: true
        }
    }

    protected async queryStringParameters(params: any = null, auth: boolean = true) {
        if (auth) {
            const usr = await this.auth.getAuthenticatedUser()

            return {
                queryStringParameters: {
                    token: usr.signInUserSession.accessToken.jwtToken,
                    query: JSON.stringify(params)
                },
                response: true
            }
        }

        return {
            queryStringParameters: { query: JSON.stringify(params) },
            response: true
        }
    }

    protected async post(additionalPath: string, params: any = null, auth: boolean = true) {
        try {
            const response = await API.post(
                this.apiName,
                this.apiPath + additionalPath,
                await this.init(params, auth)
            )

            return response.data;
        } catch(error: any) {
            throw {
                code: error.response.data.code,
                message: error.response.data.message
            } as ApiCoreResponseError
        }
    }

    protected async get(additionalPath: string, params: any = null, auth: boolean = true) {
        try {
            const response = await API.get(
                this.apiName,
                this.apiPath + additionalPath,
                await this.queryStringParameters(params, auth)
            )

            return response.data;
        } catch(error: any) {
            throw {
                code: error.response.data.code,
                message: error.response.data.message
            } as ApiCoreResponseError
        }
    }

    protected async put(additionalPath: string, params: any = null, auth: boolean = true) {
        try {
            const response = await API.put(
                this.apiName,
                this.apiPath + additionalPath,
                await this.init(params, auth)
            )

            return response.data;
        } catch(error: any) {
            throw {
                code: error.response.data.code,
                message: error.response.data.message
            } as ApiCoreResponseError
        }
    }

    protected async del(additionalPath: string, params: any = null, auth: boolean = true) {
        try {
            const response = await API.del(
                this.apiName,
                this.apiPath + additionalPath,
                await this.init(params, auth)
            )

            return response.data;
        } catch(error: any) {
            throw {
                code: error.response.data.code,
                message: error.response.data.message
            } as ApiCoreResponseError
        }
    }
}