<app-shared-navbar></app-shared-navbar>
<div class="wrapper">
    <app-home-hero></app-home-hero>
    <app-home-text></app-home-text>
    <app-home-featured-books></app-home-featured-books>
    <app-home-featured-pages></app-home-featured-pages>
    <app-home-advantages></app-home-advantages>
    <app-home-new-books></app-home-new-books>
    <app-shared-subscribe-form></app-shared-subscribe-form>
</div>
<app-shared-footer></app-shared-footer>