import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";

@Component({
    selector: "app-shared-navbar",
    templateUrl: "./shared-navbar.component.html",
    styleUrls: ["./shared-navbar.component.scss"]
})

export class SharedNavbarComponent {
    private mobileMenuIsActive = false;

    private closeMobileMenu() {
        this.mobileMenuIsActive = false;
    }

    public user: any;

    public constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    public async ngOnInit() {
        try {
            this.user = await this.authService.getAuthenticatedUser();
        } catch(error) { }
    }

    public async signout() {
        await this.authService.signOut();
    }

    public getMobileMenuState() {
        return this.mobileMenuIsActive;
    }

    public mobileMenuButtonClick() {
        this.mobileMenuIsActive = !this.mobileMenuIsActive;
    }

    public menuButtonClick(location: string) {
        this.closeMobileMenu()

        this.router.navigate([location])
    }
}
