import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from "@angular/material/menu";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { SharedNavbarComponent } from "./shared-navbar/shared-navbar.component";
import { SharedFooterComponent } from "./shared-footer/shared-footer.component";
import { SharedCookieModalComponent } from "./shared-cookie-modal/shared-cookie-modal.component";
import { SharedSubscribeFormComponent } from './shared-subscribe-form/shared-subscribe-form.component';
import { SharedLoaderComponent } from "./shared-loader/shared-loader.component";
import { SharedBookComponent } from './shared-book/shared-book.component';
import { SharedBundleComponent } from './shared-bundle/shared-bundle.component';

@NgModule({
    declarations: [
        SharedNavbarComponent,
        SharedFooterComponent,
        SharedLoaderComponent,
        SharedCookieModalComponent,
        SharedSubscribeFormComponent,
        SharedBookComponent,
        SharedBundleComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatMenuModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatMenuModule,
        SharedNavbarComponent,
        SharedFooterComponent,
        SharedLoaderComponent,
        SharedCookieModalComponent,
        SharedSubscribeFormComponent,
        SharedBookComponent,
        SharedBundleComponent
    ]
})

export class SharedModule { }