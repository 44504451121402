import { Injectable } from "@angular/core";
import { BookBaseService } from "./book-base.service";
import { Book } from "src/app/types/book.type";

@Injectable({
    providedIn: "root"
})

export class BookService extends BookBaseService {
    public async readBySlug(slug: string | null): Promise<Book> {
        return await this.get("/read/" + slug, null, false)
    }
}
