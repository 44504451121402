import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"]
})

export class HomeComponent {
    constructor(
        private titleService: Title,
        private metaService: Meta
    ) {}

    public async ngOnInit() {
        this.titleService.setTitle("Home | Coloring book empire");

        this.metaService.updateTag({
            name: "keywords",
            content: "Coloring book"
        })

        this.metaService.updateTag({
            name: "description",
            content: "Coloring book empire"
        })
    }
}
