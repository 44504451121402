import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { ContactService } from "src/app/services/contact/contact.service";
import { FormAccountService } from "src/app/services/form/form-account.service";
import { Contact } from "src/app/types/contact.type";

@Component({
    selector: "app-account-informations",
    templateUrl: "./account-informations.component.html",
    styleUrls: ["./account-informations.component.scss"]
})

export class AccountInformationsComponent {
    private updateSubscribeButtonLoaderIsActive: boolean = false;
    private updateSubscribeLoaderIsActive: boolean = true;
    private informationsLoaderIsActive: boolean = true;

    private showUpdateSubscribeButtonLoader() {
        this.updateSubscribeButtonLoaderIsActive = true;
    }

    private hideUpdateSubscribeButtonLoader() {
        this.updateSubscribeButtonLoaderIsActive = false;
    }

    private hideUpdateSubscriptionLoader() {
        this.updateSubscribeLoaderIsActive = false;
    }

    private hideInformationsLoaderIsActive() {
        this.informationsLoaderIsActive = false;
    }

    public accountUpdateSubscribeForm: FormGroup;
    public user: any;

    public constructor(
        private contactService: ContactService,
        private titleService: Title,
        private authService: AuthService,
        private formAccountService: FormAccountService, 
        private router: Router
    ) {
        this.accountUpdateSubscribeForm = formAccountService.updateSubscribe
    }

    public async ngOnInit() {
        this.titleService.setTitle("Account informations | Coloring book empire");

        this.user = (await this.authService.getAuthenticatedUser()).attributes;

        this.hideInformationsLoaderIsActive();

        const contact: Contact = await this.contactService.readByEmail();

        this.accountUpdateSubscribeForm.reset({
            settings: contact.settings
        })

        this.hideUpdateSubscriptionLoader();
    }

    public get settings() {
        return this.accountUpdateSubscribeForm.get("settings");
    }

    public getUpdateSubscribeButtonLoaderState(): boolean {
        return this.updateSubscribeButtonLoaderIsActive;
    }

    public getUpdateSubscriptionLoaderState(): boolean {
        return this.updateSubscribeLoaderIsActive;
    }

    public getInformationsLoaderState(): boolean {
        return this.informationsLoaderIsActive;
    }

    public async updateSubscribeButtonClick() {
        this.showUpdateSubscribeButtonLoader();

        const contact: Contact = await this.contactService.update({
            settings: this.settings?.value
        })

        this.accountUpdateSubscribeForm.reset({
            settings: contact.settings
        })

        this.hideUpdateSubscribeButtonLoader();
    }

    public async disconnectButtonClick() {
        await this.authService.signOut();

        this.router.navigate([ "/home" ]);
    }
}
