<app-shared-navbar></app-shared-navbar>
<div class="wrapper">
    <div class="large-text">
        <h1>Privacy Policy</h1>
        <div class="content">
            <div class="update">Last Updated: 10 October 2023</div>
            <div>This Privacy Policy explains how Coloring Book Empire ("we," "our," or "us") collects, uses, discloses, and protects your personal information when you visit our website at <a href="https://coloringbookempire.com">https://coloringbookempire.com</a> (the "Website"). By accessing or using the Website, you consent to the practices described in this Privacy Policy.</div>
            <h2>Information We Collect</h2>
            <ul>
                <li><strong>Personal Information</strong> We may collect personal information such as your name, email address, and any other information you voluntarily provide when you contact us, subscribe to our newsletter, or interact with our services.</li>
                <li><strong>Log Data</strong>: When you visit our Website, our servers automatically record information that your browser sends, including your IP address, browser type, pages of the Website you visit, the time and date of your visit, and other statistics.</li>
            </ul>
            <h2>How We Use Your Information</h2>
            <div>We use the information we collect for the following purposes:</div>
            <ul>
                <li>To provide and maintain the Website.</li>
                <li>To communicate with you, respond to your inquiries, and provide customer support.</li>
                <li>To send you updates, newsletters, marketing materials, and other information related to our products and services. You may opt-out of receiving marketing communications at any time.</li>
                <li>To monitor and analyze usage patterns and improve our Website's functionality and user experience.</li>
            </ul>
            <h2>Disclosure of Your Information</h2>
            <div>We may share your personal information with third parties under the following circumstances:</div>
            <ul>
                <li>Service Providers: We may share your information with third-party service providers who help us operate, maintain, and improve our Website.</li>
                <li>Legal Requirements: We may disclose your information when required by law or in response to valid legal requests, such as court orders or subpoenas.</li>
            </ul>
            <h2>Security</h2>
            <div>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of data transmission over the internet or electronic storage is entirely secure.</div>
            <h2>Your Choices</h2>
            <div>You can choose not to provide us with certain personal information, but this may limit your ability to use some features of the Website. You may also opt-out of receiving marketing communications from us by following the unsubscribe instructions in our emails.</div>
            <h2>Changes to This Privacy Policy</h2>
            <div>We may update this Privacy Policy from time to time. The most current version of the policy will be posted on this page with a revised "Last Updated" date.</div>
            <h2>Contact Us</h2>
            <div>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:info@coloringbookempire.com">info@coloringbookempire.com</a>.</div>
        </div>
    </div>
</div>
<app-shared-footer></app-shared-footer>