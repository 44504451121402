import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ColoringBooksComponent } from './coloring-books.component';
import { ColoringBooksResolver } from '../resolvers/coloring-books.resolver';

const routes: Routes = [
    {
        path: "coloring-books",
        component: ColoringBooksComponent,
        resolve: {
            books: ColoringBooksResolver
        },
        children: [
            {
                path: "",
                component: ColoringBooksComponent,
                pathMatch: "full"
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ColoringBooksResolver]
})

export class ColoringBooksRoutingModule { }
