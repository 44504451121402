<div *ngIf="isVisible" class="cookie-modal">
    <div class="content">
        <div class="title">This website uses cookies</div>
        <div>We use cookies to provide social media features and to analyze our traffic. We also share information about how you use our site with our web analytics, advertising and social media analytics partners, who may combine it with other information you have provided to them or which they have collected from your use of their services.</div>
        <div class="button-container">
            <button (click)="accept()" class="accept">Accept</button>
            <button (click)="decline()">Only necessary</button>
        </div>
    </div>
</div>
