import { Injectable } from "@angular/core";
import { BundleBaseService } from "./bundle-base.service";
import { ListBundlesScrollRequest } from "src/app/types/list-bundles-scroll-request.type";
import { InfiniteScrollResult } from "src/app/types/infinite-scroll-result.type";
import { ListFeaturedBundlesRequest } from "src/app/types/list-featured-bundles-request.type";

@Injectable({
    providedIn: "root"
})

export class BundlesService extends BundleBaseService {
    public async list(params: ListBundlesScrollRequest): Promise<InfiniteScrollResult> {
        return await this.get("/list", params, false)
    }

    public async listFeatured(params: ListFeaturedBundlesRequest): Promise<InfiniteScrollResult> {
        return await this.get("/list/featured", params, false)
    }
}