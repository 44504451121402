import { Injectable } from "@angular/core";
import { ApiCoreService } from "../api-core.service";

@Injectable({
    providedIn: "root"
})

export class ContactBaseService extends ApiCoreService {
    constructor() {
        super("/contacts", "contacts")
    }
}
