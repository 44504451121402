import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ColoringPagesComponent } from "./coloring-pages.component";
import { ColoringPagesResolver } from "../resolvers/coloring-pages.resolver";

const routes: Routes = [
    {
        path: "coloring-pages",
        component: ColoringPagesComponent,
        resolve: {
            bundles: ColoringPagesResolver
        },
        children: [
            {
                path: "",
                component: ColoringPagesComponent,
                pathMatch: "full"
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ColoringPagesComponent]
})

export class ColoringPagesRoutingModule { }
