import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "app-dialogs-error",
    templateUrl: "./dialogs-error.component.html",
    styleUrls: ["./dialogs-error.component.scss"]
})

export class DialogsErrorComponent {
    public message: string = "";

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<DialogsErrorComponent>
    ) { }

    public async ngOnInit(): Promise<void> {
        this.message = this.data.message;
    }
}
