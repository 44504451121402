import { Injectable } from "@angular/core";
import { ApiCoreService } from "../api-core.service";

@Injectable({
    providedIn: "root"
})

export class PaymentBaseService extends ApiCoreService {
    constructor() {
        super("/payments", "payments")
    }
}
