import { Injectable } from "@angular/core";
import { PaymentsService } from "../services/payment/payments.service";

@Injectable({
    providedIn: "root"
})

export class PaymentsResolver {
    public constructor(private paymentsService: PaymentsService) { }

    async resolve(): Promise<any> {
        let payments = "";

        try {
            payments = (await (this.paymentsService.list())).data;
        } catch(error) { }

        return payments;
    }
}