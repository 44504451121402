import { Injectable } from "@angular/core";
import { BundleBaseService } from "./bundle-base.service";
import { Bundle } from "src/app/types/bundle.type";

@Injectable({
    providedIn: "root"
})

export class BundleService extends BundleBaseService {
    public async readBySlug(slug: string | null): Promise<Bundle> {
        return await this.get("/read/" + slug, null, false)
    }
}
