import { Component } from "@angular/core";
import { Bundle } from "../types/bundle.type";
import { environment } from "src/environments/environment";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Payment } from "../types/payment.type";

@Component({
    selector: "app-page",
    templateUrl: "./page.component.html",
    styleUrls: ["./page.component.scss"]
})

export class PageComponent {
    public readonly bucketName: string = environment.WEBSITE_BUCKET_NAME;

    public bundle!: Bundle;
    public payments: Payment[] = [];

    public constructor(
        private titleService: Title,
        private metaService: Meta,
        private activatedRoute: ActivatedRoute
    ) { }

    public async ngOnInit() {

        this.activatedRoute.data.subscribe(({ bundle, payments }) => {
            this.bundle = bundle;
            this.payments = payments;

            for (let i = 0; i < this.payments.length; i++) {
                if (this.payments[i].bundleId === this.bundle.id) {
                    this.bundle.downloadLink = this.payments[i].bundleDownloadLink;
    
                    break;
                }
            }

            this.titleService.setTitle(this.bundle.seo.title)

            this.metaService.updateTag({
                name: "keywords",
                content: this.bundle.seo.keywords
            })

            this.metaService.updateTag({
                name: "description",
                content: this.bundle.seo.description
            })
        })
    }

    public shareBundleClick() {
    }
}
