import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormBaseService } from "./form-base.service";

export const AUTH_PASS_PATTERN: string = '^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).{8,}$';

@Injectable({
    providedIn: "root"
})

export class FormAuthService extends FormBaseService {
    private compare(controlName: string, matchingControlName: string) {
        return (form: FormGroup) => {
            const control = form.controls[controlName];
            const matchingControl = form.controls[matchingControlName];

            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ compare: true });
            } else {
                matchingControl.setErrors(null);
            }            
        }
    }

    public signin: FormGroup = new FormBuilder().group({
        email: [null, [Validators.required, Validators.email]],
        password: [null, [Validators.required, Validators.pattern(AUTH_PASS_PATTERN)]]
    })

    public signup: FormGroup = new FormBuilder().group({
        firstname: [null, [Validators.required]],
        lastname: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
        password: [null, [Validators.required, Validators.pattern(AUTH_PASS_PATTERN)]],
        confirmPassword: [null, [Validators.required, Validators.pattern(AUTH_PASS_PATTERN)]]
    }, {
        validators: this.compare("password", "confirmPassword")
    })

    public forgotPassword: FormGroup = new FormBuilder().group({
        email: [null, [Validators.required, Validators.email]]
    })

    public forgotPasswordSubmit: FormGroup = new FormBuilder().group({
        code: [null, [Validators.required]],
        password: [null, [Validators.required, Validators.pattern(AUTH_PASS_PATTERN)]],
        confirmPassword: [null, [Validators.required, Validators.pattern(AUTH_PASS_PATTERN)]]
    }, {
        validators: this.compare("password", "confirmPassword")
    })
}
