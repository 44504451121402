import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { ContactService } from "src/app/services/contact/contact.service";
import { FormSharedService } from "src/app/services/form/form-shared.service";
import { CreateContactRequest } from "src/app/types/create-contact-request.type";

@Component({
    selector: "app-shared-subscribe-form",
    templateUrl: "./shared-subscribe-form.component.html",
    styleUrls: ["./shared-subscribe-form.component.scss"]
})

export class SharedSubscribeFormComponent {
    private subscribeFormIsActive: boolean = true;
    private subscribeButtonLoaderIsActive: boolean = false;

    @Input() messageBoxIsActive?: boolean = false;

    private showSubscribeButtonLoader() {
        this.subscribeButtonLoaderIsActive = true;
    }

    private hideSubscibeForm() {
        this.subscribeFormIsActive = false;
    }

    public user: any;
    public subscribeForm: FormGroup;

    public constructor(
        private formSharedService: FormSharedService,
        private contactService: ContactService,
        private authService: AuthService
    ) {
        this.subscribeForm = this.formSharedService.subscribe;
    }

    public async ngOnInit() {
        try {
            this.user = await this.authService.getAuthenticatedUser();
        } catch(error) { }

        this.subscribeForm.reset({
            settings: {
                books: true,
                bundles: true
            }
        })
    }

    public get firstname() {
        return this.subscribeForm.get("firstname");
    }

    public get lastname() {
        return this.subscribeForm.get("lastname");
    }

    public get email() {
        return this.subscribeForm.get("email");
    }

    public get settings() {
        return this.subscribeForm.get("settings");
    }

    public getSubscribeButtonLoaderState(): boolean {
        return this.subscribeButtonLoaderIsActive;
    }

    public getSubscribeFormState(): boolean {
        return this.subscribeFormIsActive;
    }

    public async subscribeButtonClick() {
        if ( ! this.formSharedService.validateForm(this.subscribeForm)) {
            return
        }

        const subscriprion = localStorage.getItem("subscription")

        if (subscriprion === "true") {
            return
        }

        this.showSubscribeButtonLoader()

        const params: CreateContactRequest = {
            firstname: this.firstname?.value,
            lastname: this.lastname?.value,
            email: this.email?.value,
            settings: this.settings?.value
        }

        await this.contactService.create(params)

        localStorage.setItem("subscription", "true")

        this.hideSubscibeForm()
    }
}
