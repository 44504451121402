import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { BookService } from "../services/book/book.service";

@Injectable({
    providedIn: "root"
})

export class BookResolver {
    public constructor(private bookService: BookService) { }

    async resolve(route: ActivatedRouteSnapshot): Promise<any> {
        return await this.bookService.readBySlug(route.paramMap.get("slug"));
    }
}