import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { BundleService } from "../services/bundle/bundle.service";

@Injectable({
    providedIn: "root"
})

export class BundleResolver {
    public constructor(private bundleService: BundleService) { }

    async resolve(route: ActivatedRouteSnapshot): Promise<any> {
        return await this.bundleService.readBySlug(route.paramMap.get("slug"));
    }
}