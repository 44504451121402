import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccountRoutingModule } from "./account-routing.module";
import { AccountPaymentsComponent } from "./account-payments/account-payments.component";
import { AccountInformationsComponent } from "./account-informations/account-informations.component";
import { AccountNavbarComponent } from "./account-navbar/account-navbar.component";
import { SharedModule } from "../shared/shared.module";

@NgModule({
    declarations: [
        AccountPaymentsComponent,
        AccountInformationsComponent,
        AccountNavbarComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        AccountRoutingModule
    ],
    exports: [
        AccountNavbarComponent
    ]
})

export class AccountModule { }
