import { Injectable } from "@angular/core";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

export enum AuthResponseState {
  error,
  success,
}

@Injectable({
  providedIn: "root"
})

export class AuthService {
    constructor() {}

    private responseFormatting(state: AuthResponseState, response: any): any {
        return {
            state: state,
            response: response
        }
    }

    private success(response: any): any {
        return this.responseFormatting(AuthResponseState.success, response);
    }

    private error(error: any): any {
        return this.responseFormatting(AuthResponseState.error, error);
    }

    public async isAuthenticated(): Promise<boolean> {
        try {
            await this.getAuthenticatedUser();

            return true;
        } catch (error) {
            return false;
        }
    }

    public async getAuthenticatedUser() {
        return await Auth.currentAuthenticatedUser();
    }

    public async signIn(username: string, password: string): Promise<any> {
        try {
            let response = await Auth.signIn(username, password);

            return this.success(response);
        } catch (error) {
            return this.error(error);
        }
    }

    public async googleSignIn() {
        try {
            let response = await Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Google
            })

            return this.success(response);
        } catch (error) {
            return this.error(error);
        }
    }

    public async signUp(user: any): Promise<any> {
        try {
            let response = await Auth.signUp(user);

            return this.success(response);
        } catch (error) {
            return this.error(error);
        }
    }

    public async forgotPassword(email: string): Promise<any> {
        try {
            let response = await Auth.forgotPassword(email);

            return this.success(response);
        } catch (error) {
            return this.error(error);
        }
    }

    public async forgotPasswordSubmit(email: string, code: string, password: string) {
        try {
            let response = await Auth.forgotPasswordSubmit(email, code, password);

            return this.success(response);
        } catch (error) {
            return this.error(error);
        }
    }

    public async signOut() {
        try {
            let response = await Auth.signOut();

            return this.success(response);
        } catch (error) {
            return this.error(error);
        }
    }
}
