import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { Bundle } from "../types/bundle.type";
import { BundleStatus } from "../types/bundle-status.type";
import { ListBundlesScrollRequest } from "../types/list-bundles-scroll-request.type";
import { BundlesService } from "../services/bundle/bundles.service";
import { AuthService } from "../services/auth.service";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-coloring-pages",
    templateUrl: "./coloring-pages.component.html",
    styleUrls: [ "./coloring-pages.component.scss" ]
})

export class ColoringPagesComponent {
    public readonly bucketName: string = environment.WEBSITE_BUCKET_NAME;

    public bundles: Bundle[] = [];
    public messageBoxIsActive: boolean = false;
    public freeBundleGoogleDriveLink: string = "https://drive.google.com/drive/folders/19ugTip5QYOIDWNgw6z1vWYl_KAripSTR";
    public user: any;

    public constructor(
        private titleService: Title,
        private metaService: Meta,
        private bundlesService: BundlesService,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute
    ) {}

    public async ngOnInit() {
        try {
            this.user = await this.authService.getAuthenticatedUser();
        } catch(error) { }

        this.activatedRoute.data.subscribe(({ bundles }) => {
            this.titleService.setTitle("Coloring pages | Coloring book empire");

            this.metaService.updateTag({
                name: "keywords",
                content: ""
            })

            this.metaService.updateTag({
                name: "description",
                content: ""
            })

            this.bundles = bundles;
        })
    }

    public unauthSubscribeButtonClick() {
        const subscriprion = localStorage.getItem("subscription")

        if (subscriprion === "true") {
            window.open(this.freeBundleGoogleDriveLink, "_blank")

            return;
        }

        this.messageBoxIsActive = true;

        (document.getElementById("subscribe-form"))?.scrollIntoView()
    }

    public authSubscribeButtonClick() {
        window.open(this.freeBundleGoogleDriveLink, "_blank")
    }
}
