import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormBaseService } from "./form-base.service";

@Injectable({
    providedIn: "root"
})

export class FormAccountService extends FormBaseService {
    private subscribeSettings: FormGroup = new FormBuilder().group({
        books: [true],
        bundles: [true]
    })

    public updateSubscribe: FormGroup = new FormBuilder().group({
        settings: this.subscribeSettings
    })
}
