import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Book } from "../types/book.type";
import { environment } from "src/environments/environment";
import { Meta, Title } from "@angular/platform-browser";

@Component({
    selector: "app-book",
    templateUrl: "./book.component.html",
    styleUrls: ["./book.component.scss"]
})

export class BookComponent {
    public readonly bucketName: string = environment.WEBSITE_BUCKET_NAME;

    public book!: Book;

    public constructor(
        private titleService: Title,
        private metaService: Meta,
        private activatedRoute: ActivatedRoute
    ) { }

    public shareBookClick() {
        navigator.share({
            text: this.book.baseInformations.title,
            url: ""
        })
    }

    public async ngOnInit() {
        this.activatedRoute.data.subscribe(({ book }) => {
            this.book = book;

            this.titleService.setTitle(this.book.seo.title)

            this.metaService.updateTag({
                name: "keywords",
                content: this.book.seo.keywords
            })

            this.metaService.updateTag({
                name: "description",
                content: this.book.seo.description
            })
        })
    }
}
