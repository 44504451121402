import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TermsAndConditionsRoutingModule } from "./terms-and-conditions-routing.module";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        TermsAndConditionsRoutingModule
    ]
})

export class TermsAndConditionsModule { }
