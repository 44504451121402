export const environment = {
    production: false,
    API_BASE_PATH: "api.dev.coloringbookempire.com",
    IDENTITY_POOL_ID: "us-east-1:752d7652-ee0c-482c-8d55-361bcfd3850f",
    IDENTITY_POOL_REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_cddQq0QY3",
    USER_POOL_CLIENT_ID: "1fethu4thlqlne0uei06sftnc8",
    USER_POOL_REGION: "us-east-1",
    WEBSITE_BUCKET_REGION: "eu-east-1",
    WEBSITE_BUCKET_NAME: "dev-coloring-book-empire-website"
}