<app-shared-navbar></app-shared-navbar>
<div class="wrapper">
    <div class="large-text">
        <h1>Terms and Conditions</h1>
        <div class="content">
            <div class="update">Last Updated: 10 October 2023</div>
            <div>Please read these Terms and Conditions ("Terms") carefully before using the website at <a href="https://coloringbookempire.com">https://coloringbookempire.com</a> (the "Website") operated by Coloring Book Empire ("us," "we," or "our").</div>
            <div>By accessing or using the Website, you agree to comply with and be bound by these Terms. If you disagree with any part of these Terms, please do not use our Website.</div>
            <h2>Use of the Website</h2>
            <ul>
                <li><strong>Content</strong> You may use the Website for personal, non-commercial purposes. You may not reproduce, distribute, modify, or otherwise exploit any content on this Website without our prior written consent.</li>
                <li><strong>User Conduct</strong> You agree not to engage in any activity that could harm the Website or interfere with other users' access and use of the Website. Prohibited activities include but are not limited to hacking, distributing malware, or engaging in any illegal activities.</li>
            </ul>
            <h2>Intellectual Property</h2>
            <div>All content and materials available on the Website, including but not limited to text, graphics, logos, images, and software, are the property of Coloring Book Empire and are protected by copyright and other intellectual property laws. You may not use our intellectual property without our express written permission.</div>
            <h2>Privacy</h2>
            <div>Your use of the Website is also governed by our Privacy Policy, which can be found at <a href="/privacy-policy">link</a>.</div>
            <h2>Disclaimer</h2>
            <div>The information provided on the Website is for general informational purposes only. We make no representations or warranties of any kind, express or implied, about the accuracy, reliability, suitability, or availability of the Website or the information contained on it.</div>
            <h2>Limitation of Liability</h2>
            <div>To the fullest extent permitted by applicable law, we shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the Website; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from the Website; (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Website by any third party.</div>
            <h2>Termination</h2>
            <div>We reserve the right to terminate or suspend access to the Website immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.</div>
            <h2>Changes to Terms and Conditions</h2>
            <div>We may revise these Terms from time to time. The most current version will be posted on this page, and the date of the latest revision will be indicated at the top of the page.</div>
            <h2>Contact Us</h2>
            <div>If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:info@coloringbookempire.com">info@coloringbookempire.com</a>.</div>
        </div>
    </div>
</div>
<app-shared-footer></app-shared-footer>