<app-shared-navbar></app-shared-navbar>
<div class="wrapper">
    <div class="bundle">
        <div *ngIf="!bundle; then showLoader else showBundle"></div>
        <ng-template #showLoader>
            <app-shared-loader></app-shared-loader>
        </ng-template>
        <ng-template #showBundle>
            <div *ngIf="bundle" class="content">
                <div class="left">
                    <div class="desktop">
                        <div *ngIf="bundle.downloadLink; then showDownloadLink; else showBuyLink"></div>
                        <ng-template #showDownloadLink>
                            <div class="price-info">You have already purchased this bundle, click on the download button to download your pages.</div>
                            <div class="button-container">
                                <a class="btn--green" target="_blank" href="{{ bundle.downloadLink }}">Download</a>
                                <button class="btn" (click)="shareBundleClick()">Share</button>
                            </div>
                        </ng-template>
                        <ng-template #showBuyLink>
                            <div class="price-info">Upon completion of payment you will receive an email with the link to download your pages.</div>
                            <div class="price">{{ bundle.price / 100 | currency: "EUR" }}</div>
                            <div class="button-container">
                                <a class="btn--green" href="{{ bundle.stripeData.paymentLinkUrl }}">Buy</a>
                                <button class="btn" (click)="shareBundleClick()">Share</button>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="right">
                    <h1 [innerHTML]="bundle.baseInformations.title"></h1>
                    <img
                        class="cover"
                        title="{{ bundle.cover.title }}"
                        alt="{{ bundle.cover.alt }}"
                        src="https://{{ bucketName }}.s3.amazonaws.com/{{ bundle.cover.src }}" />
                    <div class="mobile">
                        <div *ngIf="bundle.downloadLink; then showDownloadLink; else showBuyLink"></div>
                        <ng-template #showDownloadLink>
                            <div class="price-info">You have already purchased this bundle, click on the download button to download your pages.</div>
                            <div class="button-container">
                                <a class="btn--green" target="_blank" href="{{ bundle.downloadLink }}">Download</a>
                                <button class="btn" (click)="shareBundleClick()">Share</button>
                            </div>
                        </ng-template>
                        <ng-template #showBuyLink>
                            <div class="price-info">Upon completion of payment you will receive an email with the link to download your pages.</div>
                            <div class="price">{{ bundle.price / 100 | currency: "EUR" }}</div>
                            <div class="button-container">
                                <a class="btn--green" href="{{ bundle.stripeData.paymentLinkUrl }}">Buy</a>
                                <button class="btn" (click)="shareBundleClick()">Share</button>
                            </div>
                        </ng-template>
                    </div>
                    <div class="description" [innerHTML]="bundle.baseInformations.description"></div>
                    <div class="internals-preview">
                        <div *ngFor="let preview of bundle.internalsPreview">
                            <img
                                title="{{ preview.title }}"
                                alt="{{ preview.alt }}"
                                src="https://{{ bucketName }}.s3.amazonaws.com/{{ preview.src }}" />
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
    <app-shared-subscribe-form></app-shared-subscribe-form>
</div>
<app-shared-footer></app-shared-footer>