<div class="shared-navbar">
    <div class="content">
        <img routerLink="/home" src="assets/img/logo.svg">
        <mat-icon class="mobile" (click)="mobileMenuButtonClick()">menu</mat-icon>
        <ul>
            <li routerLink="/home" routerLinkActive="active">Home</li>
            <li routerLink="/coloring-pages" routerLinkActive="active">Coloring pages</li>
            <li routerLink="/coloring-books" routerLinkActive="active">Coloring books</li>
            <li>|</li>
            <div *ngIf="user; then showAuthNavbar else showUnauthNavbar"></div>
            <ng-template #showAuthNavbar>
                <li routerLink="/account/informations" routerLinkActive="active">Account</li>
            </ng-template>
            <ng-template #showUnauthNavbar>
                <li routerLink="/auth/signin" routerLinkActive="active">Signin</li>
                <li routerLink="/auth/signup" routerLinkActive="active">Signup</li>
            </ng-template>
        </ul>
    </div>
</div>
<div *ngIf="getMobileMenuState()" class="mobile-menu">
    <ul>
        <li (click)="menuButtonClick('/home')">
            <span>Home</span>
            <mat-icon>chevron_right</mat-icon>
        </li>
        <li (click)="menuButtonClick('/coloring-pages')">
            <span>Coloring pages</span>
            <mat-icon>chevron_right</mat-icon>
        </li>
        <li (click)="menuButtonClick('/coloring-books')">
            <span>Coloring books</span>
            <mat-icon>chevron_right</mat-icon>
        </li>
        <div *ngIf="user; then showAuthNavbar else showUnauthNavbar"></div>
        <ng-template #showAuthNavbar>
            <li (click)="menuButtonClick('/account')">
                <span>Account</span>
                <mat-icon>chevron_right</mat-icon>
            </li>
        </ng-template>
        <ng-template #showUnauthNavbar>
            <li (click)="menuButtonClick('/auth/signin')">
                <span>Signin</span>
                <mat-icon>chevron_right</mat-icon>
            </li>
            <li (click)="menuButtonClick('/auth/signup')">
                <span>Signup</span>
                <mat-icon>chevron_right</mat-icon>
            </li>
        </ng-template>
    </ul>
</div>