<div class="signup-form">
    <div class="content">
        <h1>Signup</h1>
        <form [formGroup]="signupForm">
            <div class="text">Register to track your purchases and have access to your coloring pages from all your devices.</div>
            <fieldset>
                <input type="text" placeholder="First name" formControlName="firstname" />
                <div *ngIf="firstname?.invalid && (firstname?.dirty || firstname?.touched)">
                    <div class="error" *ngIf="firstname?.errors?.['required']">
                        First name is required.
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <input type="text" placeholder="Last name" formControlName="lastname" />
                <div *ngIf="lastname?.invalid && (lastname?.dirty || lastname?.touched)">
                    <div class="error" *ngIf="lastname?.errors?.['required']">
                        Last name is required.
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <input type="text" placeholder="Email" formControlName="email" />
                <div *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                    <div class="error" *ngIf="email?.errors?.['required']">
                        Email is required.
                    </div>
                    <div class="error" *ngIf="email?.errors?.['email']">
                        Invalid email.
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <div class="fake-input">
                    <input [type]="getSignupPasswordType()" placeholder="Password" formControlName="password" />
                    <div *ngIf="getSignupPasswordType() == 'text'; then showVisibilityIcon; else showVisibilityOffIcon"></div>
                    <ng-template #showVisibilityIcon>
                        <mat-icon (click)="showPasswordTypeToggle()">
                            visibility
                        </mat-icon>
                    </ng-template>
                    <ng-template #showVisibilityOffIcon>
                        <mat-icon (click)="showPasswordTypeToggle()">
                            visibility_off
                        </mat-icon>
                    </ng-template>
                </div>
                <div *ngIf="password?.invalid && (password?.dirty || password?.touched)">
                    <div class="error" *ngIf="password?.errors?.['required']">
                        Password is required.
                    </div>
                    <div class="error" *ngIf="password?.errors?.['pattern']">
                        The password must be at least 8 characters and contain at least one uppercase, one lowercase one symbol and a number.
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <div class="fake-input">
                    <input [type]="getSignupPasswordType()" placeholder="Confirm password" formControlName="confirmPassword" />
                    <div *ngIf="getSignupPasswordType() == 'text'; then showVisibilityIcon; else showVisibilityOffIcon"></div>
                    <ng-template #showVisibilityIcon>
                        <mat-icon (click)="showPasswordTypeToggle()">
                            visibility
                        </mat-icon>
                    </ng-template>
                    <ng-template #showVisibilityOffIcon>
                        <mat-icon (click)="showPasswordTypeToggle()">
                            visibility_off
                        </mat-icon>
                    </ng-template>
                </div>
                <div *ngIf="confirmPassword?.invalid && (confirmPassword?.dirty || confirmPassword?.touched)">
                    <div class="error" *ngIf="confirmPassword?.errors?.['required']">
                        Confirm password is required.
                    </div>
                    <div class="error" *ngIf="confirmPassword?.errors?.['compare']">
                        The password does not match.
                    </div>
                </div>
            </fieldset>
            <button
                class="btn--green"
                [disabled]="getSignupButtonLoaderState()"
                (click)="signUpButtonClick()">
                <div *ngIf="
                    getSignupButtonLoaderState();
                    then showSignupButtonLoader;
                    else hideSignupButtonLoader">
                </div>
                <ng-template #showSignupButtonLoader>
                    <mat-spinner [diameter]="20"></mat-spinner>
                </ng-template>
                <ng-template #hideSignupButtonLoader>
                    Signup
                </ng-template>
            </button>
            <div class="message">By registering you agree to our <a href="/privacy-policy">Privacy Policy</a> and <a href="/terms-and-conditions">Terms and Conditions</a> of service.</div>
        </form>
    </div>
</div>
