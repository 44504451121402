import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthRoutingModule } from "./auth-routing.module";
import { AuthSigninComponent } from "./auth-signin/auth-signin.component";
import { AuthSignupComponent } from "./auth-signup/auth-signup.component";
import { SharedModule } from "../shared/shared.module";
import { AuthComponent } from "./auth.component";
import { AuthSignupConfirmationComponent } from "./auth-signup-confirmation/auth-signup-confirmation.component";
import { AuthForgotPasswordComponent } from "./auth-forgot-password/auth-forgot-password.component";

@NgModule({
    declarations: [
        AuthComponent,
        AuthSigninComponent,
        AuthSignupComponent,
        AuthSignupConfirmationComponent,
        AuthForgotPasswordComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        AuthRoutingModule
    ]
})

export class AuthModule { }
