import { Component, OnInit } from "@angular/core";
import { BooksService } from "src/app/services/book/books.service";
import { Book } from "src/app/types/book.type";
import { ListNewBooksRequest } from "src/app/types/list-new-books-request.type";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-home-new-books",
    templateUrl: "./home-new-books.component.html",
    styleUrls: ["./home-new-books.component.scss"]
})

export class HomeNewBooksComponent implements OnInit {
    public readonly bucketName: string = environment.WEBSITE_BUCKET_NAME;

    public books: Book[] = [];

    public constructor(
        private booksService: BooksService
    ) { }

    public async ngOnInit() {
        const params: ListNewBooksRequest = {
            limit: 4
        }

        this.books = (await this.booksService.listNew(params)).data;
    }
}
