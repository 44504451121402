<div *ngIf="!user" id="subscribe-form" class="subscribe-form">
    <div class="content">
        <div *ngIf="getSubscribeFormState(); then showSubscribeForm else showThankYou"></div>
        <ng-template #showSubscribeForm>
            <h2>Don't  miss updates from us!</h2>
            <h3>New content for you every month</h3>
            <div class="message" *ngIf="messageBoxIsActive">* subscribe to our newsletter or sign up to have access to our free content.</div>
            <form [formGroup]="subscribeForm">
                <div class="double">
                    <fieldset>                    
                        <input type="text" placeholder="First name" formControlName="firstname" />
                        <div *ngIf="firstname?.invalid && (firstname?.dirty || firstname?.touched)">
                            <div class="error" *ngIf="firstname?.errors?.['required']">
                                First name is required.
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <input type="text" placeholder="Last name" formControlName="lastname" />
                        <div *ngIf="lastname?.invalid && (lastname?.dirty || lastname?.touched)">
                            <div class="error" *ngIf="lastname?.errors?.['required']">
                                Last name is required.
                            </div>
                        </div>
                    </fieldset>
                </div>
                <fieldset>
                    <input type="text" placeholder="Email" formControlName="email" />
                    <div *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                        <div class="error" *ngIf="email?.errors?.['required']">
                            Email is required.
                        </div>
                        <div class="error" *ngIf="email?.errors?.['email']">
                            Invalid email.
                        </div>
                    </div>
                </fieldset>
                <div formGroupName="settings">
                    <fieldset>
                        <mat-checkbox formControlName="books"></mat-checkbox>
                        <label>subscribe coloring books newsletter</label>
                    </fieldset>
                    <fieldset>
                        <mat-checkbox formControlName="bundles"></mat-checkbox>
                        <label>subscribe coloring pages newsletter</label>
                    </fieldset>
                    <div *ngIf="settings?.invalid && (settings?.dirty || settings?.touched)">
                        <div class="error" *ngIf="settings?.errors?.['checkbox']">
                            You must select at least one option.
                        </div>
                    </div>
                </div>
                <div class="button-container">
                    <button
                        class="btn--green"
                        [disabled]="getSubscribeButtonLoaderState()"
                        (click)="subscribeButtonClick()">
                        <div *ngIf="
                            getSubscribeButtonLoaderState();
                            then showSubscribeButtonLoader;
                            else hideSubscribeButtonLoader">
                        </div>
                        <ng-template #showSubscribeButtonLoader>
                            <mat-spinner [diameter]="20"></mat-spinner>
                        </ng-template>
                        <ng-template #hideSubscribeButtonLoader>
                            Subscribe now!
                        </ng-template>
                    </button>
                </div>
            </form>
        </ng-template>
        <ng-template #showThankYou>
            <h2>Thank you for your subscription!</h2>
            <h3>You will receive an email every time we publish new content for you!</h3>
        </ng-template>
    </div>
</div>
