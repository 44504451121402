import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormBaseService } from "./form-base.service";

@Injectable({
    providedIn: "root"
})

export class FormSharedService extends FormBaseService {
    private checkbox() {
        return (form: FormGroup) => {
            const controls = form.controls["settings"].value;

            for (let controlName of Object.keys(controls)) {
                if (controls[controlName]) {
                    return true;
                }
            }

            form.controls["settings"].setErrors({ checkbox: true })

            return false;
        }
    }

    private subscribeSettings: FormGroup = new FormBuilder().group({
        books: [true],
        bundles: [true]
    })

    public subscribe: FormGroup = new FormBuilder().group({
        email: [null, [Validators.required, Validators.email]],
        firstname: [null, [Validators.required]],
        lastname: [null, [Validators.required]],
        settings: this.subscribeSettings
    }, {
        validators: this.checkbox()
    })
}
