import { NgModule } from '@angular/core';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { CommonModule } from '@angular/common';
import { HomeHeroComponent } from './home-hero/home-hero.component';
import { HomeShareComponent } from './home-share/home-share.component';
import { HomeNewBooksComponent } from './home-new-books/home-new-books.component';
import { HomeFeaturedBooksComponent } from './home-featured-books/home-featured-books.component';
import { HomeTextComponent } from './home-text/home-text.component';
import { HomeAdvantagesComponent } from './home-advantages/home-advantages.component';
import { SharedModule } from '../shared/shared.module';
import { HomeFeaturedPagesComponent } from './home-featured-pages/home-featured-pages.component';

@NgModule({
    declarations: [
        HomeComponent,
        HomeHeroComponent,
        HomeShareComponent,
        HomeNewBooksComponent,
        HomeFeaturedBooksComponent,
        HomeTextComponent,
        HomeAdvantagesComponent,
        HomeFeaturedPagesComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        HomeRoutingModule
    ],
    exports: []
})

export class HomeModule {}
