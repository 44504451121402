import { Component } from "@angular/core";
import { BooksService } from "src/app/services/book/books.service";
import { BookStatus } from "src/app/types/book-status.type";
import { Book } from "src/app/types/book.type";
import { ListBooksScrollRequest } from "src/app/types/list-books-scroll-request.type";

@Component({
    selector: "app-shared-footer",
    templateUrl: "./shared-footer.component.html",
    styleUrls: ["./shared-footer.component.scss"]
})

export class SharedFooterComponent {
    public books: Book[] = [];

    public constructor(
        private booksService: BooksService
    ) { }

    public async ngOnInit() {
        const params: ListBooksScrollRequest = {
            limit: 25,
            status: BookStatus.Enabled
        }

        this.books = (await this.booksService.list(params)).data;
    }
}