import { Injectable } from "@angular/core";
import { BooksService } from "../services/book/books.service";
import { ListBooksScrollRequest } from "../types/list-books-scroll-request.type";
import { BookStatus } from "../types/book-status.type";

@Injectable({
    providedIn: "root"
})

export class ColoringBooksResolver {
    public constructor(private booksService: BooksService) { }

    async resolve(): Promise<any> {
        const params: ListBooksScrollRequest = {
            limit: 100,
            status: BookStatus.Enabled
        }

        return (await this.booksService.list(params)).data;
    }
}