<app-shared-navbar></app-shared-navbar>
<div class="wrapper">
    <div class="book">
        <div *ngIf="!book; then showLoader else showBook"></div>
        <ng-template #showLoader>
            <app-shared-loader></app-shared-loader>
        </ng-template>
        <ng-template #showBook>
            <div *ngIf="book" class="content">
                <div class="left">
                    <h1 class="mobile" [innerHTML]="book.baseInformations.title"></h1>
                    <img
                        title="{{ book.cover.title }}"
                        alt="{{ book.cover.alt }}"
                        src="https://{{ bucketName }}.s3.amazonaws.com/{{ book.cover.src }}" />
                    <div class="button-container">
                        <a class="btn--green" href="{{ book.link }}" target="_blank">Buy on Amazon</a>
                        <div *ngIf="book.alternativeLink; then showAlternativeLink"></div>
                        <ng-template #showAlternativeLink>
                            <a class="btn--green" href="{{ book.alternativeLink }}" target="_blank">Alternative Buy</a>
                        </ng-template>
                        <button class="btn" (click)="shareBookClick()">Share</button>
                    </div>
                </div>
                <div class="right">
                    <h1 class="desktop" [innerHTML]="book.baseInformations.title"></h1>
                    <div class="description" [innerHTML]="book.baseInformations.description"></div>
                    <div class="internals-preview">
                        <div *ngFor="let preview of book.internalsPreview">
                            <img
                                title="{{ preview.title }}"
                                alt="{{ preview.alt }}"
                                src="https://{{ bucketName }}.s3.amazonaws.com/{{ preview.src }}" />
                        </div>
                    </div>
                    <div *ngIf="book.bundleLink; then showBundleLink"></div>
                    <ng-template #showBundleLink>
                        <div class="print">
                            <div>Do you have a printer? purchase the printable version, after payment you will receive an email with the link to download your pages, print and start coloring immediately!</div>
                            <a class="btn--green" href="{{ book.bundleLink }}">Buy Printable Version</a>
                        </div>
                    </ng-template>
                </div>
            </div>
        </ng-template>
    </div>
    <app-shared-subscribe-form></app-shared-subscribe-form>
</div>
<app-shared-footer></app-shared-footer>