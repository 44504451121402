import { Injectable } from "@angular/core";
import { ApiCoreService } from "../api-core.service";

@Injectable({
    providedIn: "root"
})

export class BundleBaseService extends ApiCoreService {
    constructor() {
        super("/bundles", "bundles")
    }
}
