import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageComponent } from "./page.component";
import { BundleResolver } from "../resolvers/bundle.resolver";
import { PaymentsResolver } from "../resolvers/payments.resolver";

const routes: Routes = [
    {
        path: "page/:slug",
        component: PageComponent,
        resolve: {
            bundle: BundleResolver,
            payments: PaymentsResolver
        },
        children: [
            {
                path: "",
                component: PageComponent,
                pathMatch: "full"
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [BundleResolver, PaymentsResolver]
})

export class PageRoutingModule { }
