import { Injectable } from "@angular/core";
import { InfiniteScrollResult } from "src/app/types/infinite-scroll-result.type";
import { PaymentBaseService } from "./payment-base.service";

@Injectable({
    providedIn: "root"
})

export class PaymentsService extends PaymentBaseService {
    public async list(): Promise<InfiniteScrollResult> {
        return await this.get("/list/email", null, true)
    }
}