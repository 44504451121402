import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { ColoringBooksComponent } from "./coloring-books/coloring-books.component";
import { ColoringBooksRoutingModule } from "./coloring-books/coloring-books-routing.module";
import { BookComponent } from "./book/book.component";
import { BookRoutingModule } from "./book/book-routing.module";
import { ColoringPagesComponent } from "./coloring-pages/coloring-pages.component";
import { ColoringPagesRoutingModule } from "./coloring-pages/coloring-pages-routing.module";
import { PageComponent } from "./page/page.component";
import { AuthModule } from "./auth/auth.module";
import { HomeModule } from "./home/home.module";
import { DialogsErrorComponent } from "./dialogs/dialogs-error/dialogs-error.component";
import { AccountComponent } from './account/account.component';
import { AccountModule } from "./account/account.module";
import { ThankYouPageComponent } from './thank-you-page/thank-you-page.component';
import { ThankYouPageModule } from "./thank-you-page/thank-you-page.module";
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PageRoutingModule } from "./page/page-routing.module";
import { PrivacyPolicyModule } from "./privacy-policy/privacy-policy.module";
import { TermsAndConditionsModule } from "./terms-and-conditions/terms-and-conditions.module";
import { ContactsComponent } from './contacts/contacts.component';

@NgModule({
    declarations: [
        AppComponent,
        ColoringBooksComponent,
        BookComponent,
        ColoringPagesComponent,
        PageComponent,
        DialogsErrorComponent,
        AccountComponent,
        ThankYouPageComponent,
        PrivacyPolicyComponent,
        TermsAndConditionsComponent,
        ContactsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        AuthModule,
        HomeModule,
        ColoringBooksRoutingModule,
        BookRoutingModule,
        ColoringPagesRoutingModule,
        PageRoutingModule,
        AccountModule,
        ThankYouPageModule,
        PrivacyPolicyModule,
        TermsAndConditionsModule
    ],
    providers: [],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule { }
