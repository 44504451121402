<app-shared-navbar></app-shared-navbar>
<div class="wrapper">
    <div class="books">
        <h1>Our Coloring Books</h1>
        <h3>The perfect gift for any occasion!</h3>
        <div class="content">
            <div *ngIf="books.length == 0; then showLoader else showBooks"></div>
            <ng-template #showLoader>
                <app-shared-loader></app-shared-loader>
            </ng-template>
            <ng-template #showBooks>
                <div *ngFor="let book of books">
                    <app-shared-book [book]="book"></app-shared-book>
                </div>
            </ng-template>
        </div>
    </div>
    <app-shared-subscribe-form></app-shared-subscribe-form>
</div>
<app-shared-footer></app-shared-footer>
