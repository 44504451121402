import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PrivacyPolicyComponent } from "./privacy-policy.component";

const routes: Routes = [
    {
        path: "privacy-policy",
        component: PrivacyPolicyComponent,
        children: [
            {
                path: "",
                component: PrivacyPolicyComponent,
                pathMatch: "full"
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class PrivacyPolicyRoutingModule { }
