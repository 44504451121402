<div class="payments">
    <div class="content">
        <h2>Your payments</h2>
        <div *ngIf="getPaymentsLoaderState(); then showLoader else showPayments"></div>
        <ng-template #showPayments>
            <div *ngIf="payments.length == 0; then showPaymentsNotFound else showPaymentsList"></div>
            <ng-template #showPaymentsList>
                <div *ngFor="let payment of payments" class="payment">
                    <div>
                        <div class="price">{{ payment.amount / 100 | currency: "EUR" }}</div>
                        <div class="title">{{ payment.bundleTitle }}</div>
                    </div>
                    <div>
                        <a class="btn--green" href="{{ payment.bundleDownloadLink }}" target="_blank">
                            Download
                        </a>
                    </div>
                </div>
            </ng-template>
            <ng-template #showPaymentsNotFound>
                You have not made any payments.
            </ng-template>
        </ng-template>
        <ng-template #showLoader>
            <app-shared-loader></app-shared-loader>
        </ng-template>
    </div>
</div>
