import { Injectable } from "@angular/core";
import { BundlesService } from "../services/bundle/bundles.service";
import { ListBundlesScrollRequest } from "../types/list-bundles-scroll-request.type";
import { BundleStatus } from "../types/bundle-status.type";

@Injectable({
    providedIn: "root"
})

export class ColoringPagesResolver {
    public constructor(private bundlesService: BundlesService) { }

    async resolve(): Promise<any> {
        const params: ListBundlesScrollRequest = {
            limit: 100,
            status: BundleStatus.Enabled
        }

        return (await this.bundlesService.list(params)).data;
    }
}