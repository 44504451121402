import { Component } from '@angular/core';

@Component({
    selector: 'app-shared-cookie-modal',
    templateUrl: './shared-cookie-modal.component.html',
    styleUrls: ['./shared-cookie-modal.component.scss']
})

export class SharedCookieModalComponent {
    private storageName: string = 'cookie-modal';

    public isVisible: boolean = true;

    public ngOnInit() {
        let isVisible = localStorage.getItem(this.storageName);

        if (isVisible === 'true') {
            this.isVisible = false;
        }
    }

    public accept() {
        this.isVisible = false;

        localStorage.setItem(this.storageName, 'true');
    }

    public decline() {
        this.isVisible = false;

        localStorage.setItem(this.storageName, 'true');
    }
}
