import { Component, Input } from "@angular/core";
import { Book } from "src/app/types/book.type";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-shared-book",
    templateUrl: "./shared-book.component.html",
    styleUrls: ["./shared-book.component.scss"]
})

export class SharedBookComponent {
    public readonly bucketName: string = environment.WEBSITE_BUCKET_NAME;

    @Input() book: Book | undefined;
}
