<div class="signin-form">
    <div class="content">
        <h1>Signin</h1>
        <form [formGroup]="signinForm">
            <fieldset>
                <input type="text" placeholder="Email" formControlName="email" />
                <div *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                    <div class="error" *ngIf="email?.errors?.['required']">
                        Email is required.
                    </div>
                    <div class="error" *ngIf="email?.errors?.['email']">
                        Invalid email.
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <div class="fake-input">
                    <input [type]="getSigninPasswordType()" placeholder="Password" formControlName="password" />
                    <div *ngIf="getSigninPasswordType() == 'text'; then showVisibilityIcon; else showVisibilityOffIcon"></div>
                    <ng-template #showVisibilityIcon>
                        <mat-icon (click)="showPasswordTypeToggle()">
                            visibility
                        </mat-icon>
                    </ng-template>
                    <ng-template #showVisibilityOffIcon>
                        <mat-icon (click)="showPasswordTypeToggle()">
                            visibility_off
                        </mat-icon>
                    </ng-template>
                </div>
                <div *ngIf="password?.invalid && (password?.dirty || password?.touched)">
                    <div class="error" *ngIf="password?.errors?.['required']">
                        Password is required.
                    </div>
                    <div class="error" *ngIf="password?.errors?.['pattern']">
                        The password must be at least 8 characters and contain at least one uppercase, one lowercase one symbol and a number.
                    </div>
                </div>
            </fieldset>
            <button
                class="btn--green"
                [disabled]="getSigninButtonLoaderState()"
                (click)="signInButtonClick()">
                <div *ngIf="
                    getSigninButtonLoaderState();
                    then showSigninButtonLoader;
                    else hideSigninButtonLoader">
                </div>
                <ng-template #showSigninButtonLoader>
                    <mat-spinner [diameter]="20"></mat-spinner>
                </ng-template>
                <ng-template #hideSigninButtonLoader>
                    Signin
                </ng-template>
            </button>
            <div class="link-container">
                <div><a href="/auth/forgot-password">Forgot your password?</a></div>
                <div>Do not have an account? <a href="/auth/signup">Signup</a></div>
            </div>
        </form>
    </div>
</div>
