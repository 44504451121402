import { Component, Input } from "@angular/core";
import { Bundle } from "src/app/types/bundle.type";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-shared-bundle",
    templateUrl: "./shared-bundle.component.html",
    styleUrls: ["./shared-bundle.component.scss"]
})

export class SharedBundleComponent {
    public readonly bucketName: string = environment.WEBSITE_BUCKET_NAME;

    @Input() bundle: Bundle | undefined;
}
