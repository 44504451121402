import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { FormAuthService } from "src/app/services/form/form-auth.service";
import { UtilsDialogsService } from "src/app/services/utils/utils-dialogs.service";

@Component({
    selector: "app-auth-forgot-password",
    templateUrl: "./auth-forgot-password.component.html",
    styleUrls: ["./auth-forgot-password.component.scss"]
})

export class AuthForgotPasswordComponent {
    private forgotPasswordFormIsActive: boolean = true;
    private forgotPasswordButtonLoaderIsActive: boolean = false;
    private forgotPasswordSubmitButtonLoaderIsActive: boolean = false;
    private currentEmail: string = "";

    private showForgotPasswordButtonLoader() {
        this.forgotPasswordButtonLoaderIsActive = true;
    }

    private hideForgotPasswordButtonLoader() {
        this.forgotPasswordButtonLoaderIsActive = false;
    }

    private hideForgotPasswordForm() {
        this.forgotPasswordFormIsActive = false;
    }

    private showForgotPasswordSubmitButtonLoader() {
        this.forgotPasswordSubmitButtonLoaderIsActive = true;
    }

    private hideForgotPasswordSubmitButtonLoader() {
        this.forgotPasswordSubmitButtonLoaderIsActive = false;
    }

    public forgotPasswordForm: FormGroup;
    public forgotPasswordSubmitForm: FormGroup;

    public constructor(
        private formAuthService: FormAuthService,
        private authService: AuthService,
        private utilsDialogsService: UtilsDialogsService,
        private router: Router
    ) {
        this.forgotPasswordForm = this.formAuthService.forgotPassword;
        this.forgotPasswordSubmitForm = this.formAuthService.forgotPasswordSubmit;
    }

    public get email() {
        return this.forgotPasswordForm.get("email");
    }

    public get code() {
        return this.forgotPasswordSubmitForm.get("code");
    }

    public get password() {
        return this.forgotPasswordSubmitForm.get("password");
    }

    public get confirmPassword() {
        return this.forgotPasswordSubmitForm.get("confirmPassword");
    }

    public getForgotPasswordButtonLoaderState(): boolean {
        return this.forgotPasswordButtonLoaderIsActive;
    }

    public getForgotPasswordSubmitButtonLoaderState(): boolean {
        return this.forgotPasswordSubmitButtonLoaderIsActive;
    }

    public getForgotPasswordFormState(): boolean {
        return this.forgotPasswordFormIsActive;
    }

    public async forgotPasswordButtonClick() {
        if ( ! this.formAuthService.validateForm(this.forgotPasswordForm)) {
            return;
        }

        this.showForgotPasswordButtonLoader();

        const data = await this.authService.forgotPassword(this.email?.value);

        if (data.response.code === "UserNotFoundException") {
            this.hideForgotPasswordButtonLoader();

            this.utilsDialogsService.showError("Email does not exist.");

            return;
        }

        if (data.response.code === "LimitExceededException") {
            this.hideForgotPasswordButtonLoader();

            this.utilsDialogsService.showError("Attempt limit exceeded, please try after some time.");

            return;
        }

        this.currentEmail = this.email?.value;

        this.hideForgotPasswordForm();
    }

    public async forgotPasswordSubmitButtonClick() {
        if ( ! this.formAuthService.validateForm(this.forgotPasswordSubmitForm)) {
            return;
        }

        this.showForgotPasswordSubmitButtonLoader();

        const data = await this.authService.forgotPasswordSubmit(this.currentEmail, this.code?.value, this.password?.value);

        if (data.response.code === "CodeMismatchException") {
            this.hideForgotPasswordSubmitButtonLoader();

            this.utilsDialogsService.showError("Invalid verification code provided, please try again.");

            return;
        }

        this.router.navigate(["/auth/signin"]);
    }
}
