import { Injectable } from "@angular/core";
import { ContactBaseService } from "./contact-base.service";
import { Contact } from "src/app/types/contact.type";
import { CreateContactRequest } from "src/app/types/create-contact-request.type";
import { UpdateContactRequest } from "src/app/types/update-contact-request.type";

@Injectable({
    providedIn: "root"
})

export class ContactService extends ContactBaseService {
    public async create(params: CreateContactRequest): Promise<Contact> {
        return await this.post("/", params, false)
    }

    public async readByEmail(): Promise<Contact> {
        return await this.get("/read/email", null, true)
    }

    public async update(params: UpdateContactRequest): Promise<Contact> {
        return await this.put("/", params, true)
    }
}
