import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AccountComponent } from "./account.component";
import { AuthGuard } from "../guards/auth.guard";
import { AccountInformationsComponent } from "./account-informations/account-informations.component";
import { AccountPaymentsComponent } from "./account-payments/account-payments.component";

const routes: Routes = [
    {
        path: "account",
        component: AccountComponent,
        children: [
            {
                path: "",
                component: AccountInformationsComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }, {
                path: "informations",
                component: AccountInformationsComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }, {
                path: "payments",
                component: AccountPaymentsComponent,
                pathMatch: "full",
                canActivate: [ AuthGuard ]
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AccountRoutingModule { }
