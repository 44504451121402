<div class="informations">
    <div class="content">
        <h2>Your informations</h2>
        <div *ngIf="getInformationsLoaderState(); then showInformationLoader; else showInformationsData"></div>
        <ng-template #showInformationsData>
            <form class="fake-form">
                <fieldset>
                    <label>First name</label>
                    <input class="disabled" type="text" value="{{ user['custom:firstname'] }}" readonly>
                </fieldset>
                <fieldset>
                    <label>Last name</label>
                    <input class="disabled" type="text" value="{{ user['custom:lastname'] }}" readonly>
                </fieldset>
                <fieldset>
                    <label>Email</label>
                    <input class="disabled" type="text" value="{{ user['email'] }}" readonly>
                </fieldset>
            </form>
        </ng-template>
        <ng-template #showInformationLoader>
            <mat-spinner [diameter]="20"></mat-spinner>
        </ng-template>
        <h2>Your newsletters subscription</h2>
        <div *ngIf="getUpdateSubscriptionLoaderState(); then showSubscriptionLoader; else showSubscriptionForm"></div>
        <ng-template #showSubscriptionForm>
            <form [formGroup]="accountUpdateSubscribeForm">
                <div formGroupName="settings">
                    <fieldset>
                        <mat-checkbox formControlName="books"></mat-checkbox>
                        <label>subscribe coloring books newsletter</label>
                    </fieldset>
                    <fieldset>
                        <mat-checkbox formControlName="bundles"></mat-checkbox>
                        <label>subscribe coloring pages newsletter</label>
                    </fieldset>
                </div>
                <div class="button-container">
                    <button
                        class="btn--green"
                        [disabled]="getUpdateSubscribeButtonLoaderState()"
                        (click)="updateSubscribeButtonClick()">
                        <div *ngIf="
                            getUpdateSubscribeButtonLoaderState();
                            then showUpdateSubscribeButtonLoader;
                            else hideUpdateSubscribeButtonLoader">
                        </div>
                        <ng-template #showUpdateSubscribeButtonLoader>
                            <mat-spinner [diameter]="20"></mat-spinner>
                        </ng-template>
                        <ng-template #hideUpdateSubscribeButtonLoader>
                            Update
                        </ng-template>
                    </button>
                </div>
            </form>
        </ng-template>
        <ng-template #showSubscriptionLoader>
            <app-shared-loader></app-shared-loader>
        </ng-template>
        <h2>Disconnect to your account</h2>
        <button class="btn" (click)="disconnectButtonClick()">Disconnect</button>
    </div>
</div>