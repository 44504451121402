import { Component } from "@angular/core";
import { BundlesService } from "src/app/services/bundle/bundles.service";
import { Bundle } from "src/app/types/bundle.type";
import { ListFeaturedBundlesRequest } from "src/app/types/list-featured-bundles-request.type";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-home-featured-pages",
    templateUrl: "./home-featured-pages.component.html",
    styleUrls: ["./home-featured-pages.component.scss"]
})

export class HomeFeaturedPagesComponent {
    public readonly bucketName: string = environment.WEBSITE_BUCKET_NAME;

    public bundles: Bundle[] = [];

    public constructor(
        private bundlesService: BundlesService
    ) { }

    public async ngOnInit() {
        const params: ListFeaturedBundlesRequest = {
            limit: 4
        }

        this.bundles = (await this.bundlesService.listFeatured(params)).data;
    }
}
