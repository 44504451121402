<div class="featured">
    <h2>Featured Coloring Books</h2>
    <h3>Our best selling books!</h3>
    <div class="content">
        <div *ngIf="books.length == 0; then showLoader else showBooks"></div>
        <ng-template #showBooks>
            <div *ngFor="let book of books">
                <app-shared-book [book]="book"></app-shared-book>
            </div>
        </ng-template>
        <ng-template #showLoader>
            <app-shared-loader></app-shared-loader>
        </ng-template>
    </div>
    <a class="link" href="/coloring-books">View all</a>
</div>
