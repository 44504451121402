import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { FormAuthService } from "src/app/services/form/form-auth.service";
import { UtilsDialogsService } from "src/app/services/utils/utils-dialogs.service";

@Component({
    selector: "app-auth-signin",
    templateUrl: "./auth-signin.component.html",
    styleUrls: ["./auth-signin.component.scss"]
})

export class AuthSigninComponent {
    private signinButtonLoaderIsActive: boolean = false;
    private showSigninPassword: boolean = false;

    private showSigninButtonLoader() {
        this.signinButtonLoaderIsActive = true;
    }

    private hideSigninButtonLoader() {
        this.signinButtonLoaderIsActive = false;
    }

    public signinForm: FormGroup;

    public constructor(
        private formAuthService: FormAuthService,
        private auth: AuthService,
        private utilsDialogsService: UtilsDialogsService,
        private titleService: Title,
        private metaService: Meta,
        private router: Router
    ) {
        this.signinForm = this.formAuthService.signin;
    }

    public ngOnInit() {
        this.signinForm.reset();

        this.titleService.setTitle("Signin | Coloring book empire");

        this.metaService.updateTag({
            name: "keywords",
            content: ""
        })

        this.metaService.updateTag({
            name: "description",
            content: ""
        })
    }

    public get email() {
        return this.signinForm.get("email");
    }

    public get password() {
        return this.signinForm.get("password");
    }

    public getSigninButtonLoaderState(): boolean {
        return this.signinButtonLoaderIsActive;
    }

    public showPasswordTypeToggle() {
        this.showSigninPassword = !this.showSigninPassword;
    }

    public getSigninPasswordType(): "text" | "password" {
        if (this.showSigninPassword) {
            return "text";
        }

        return "password";
    }

    public async signInButtonClick() {
        if ( ! this.formAuthService.validateForm(this.signinForm)) {
            return;
        }

        this.showSigninButtonLoader();

        const data = await this.auth.signIn(this.email?.value, this.password?.value);

        if (data.response.code === "UserNotFoundException") {
            this.hideSigninButtonLoader();

            this.utilsDialogsService.showError("User does not exist.");

            return;
        }

        if (data.response.code === "NotAuthorizedException") {
            this.hideSigninButtonLoader();

            this.utilsDialogsService.showError("Incorrect username or password.");

            return;
        }

        if (data.response.code === "UserNotConfirmedException") {
            this.hideSigninButtonLoader();

            this.utilsDialogsService.showError("Your email address is not confirmed.");

            return;
        }

        this.router.navigate(["/account/informations"])
    }
}
