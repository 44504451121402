<div class="featured">
    <h2>Featured Coloring Pages</h2>
    <h3>Buy, instant download, print and color!</h3>
    <div class="content">
        <div *ngIf="bundles.length == 0; then showLoader else showBundles"></div>
        <ng-template #showBundles>
            <div *ngFor="let bundle of bundles">
                <app-shared-bundle [bundle]="bundle"></app-shared-bundle>
            </div>
        </ng-template>
        <ng-template #showLoader>
            <app-shared-loader></app-shared-loader>
        </ng-template>
    </div>
    <a class="link" href="/coloring-pages">View all</a>
</div>
