<app-shared-navbar></app-shared-navbar>
<div class="wrapper">
    <div class="coloring-pages">
        <h1>Our Coloring Pages</h1>
        <h3>Buy, instant download, print and color!</h3>
        <div class="content">
            <div *ngIf="bundles.length == 0; then showLoader else showBundles"></div>
            <ng-template #showLoader>
                <app-shared-loader></app-shared-loader>
            </ng-template>
            <ng-template #showBundles>
                <div class="bundle">
                    <img src="assets/img/bundles/free/1.jpg"/>
                    <div *ngIf="user; then showAuthButton else showUnauthButton"></div>
                    <ng-template #showAuthButton>
                        <button class="btn--green" (click)="authSubscribeButtonClick()">Download</button>
                    </ng-template>
                    <ng-template #showUnauthButton>
                        <button class="btn--green" (click)="unauthSubscribeButtonClick()">Free Download</button>
                    </ng-template>
                </div>
                <div *ngFor="let bundle of bundles">
                    <app-shared-bundle [bundle]="bundle"></app-shared-bundle>
                </div>
            </ng-template>
        </div>
    </div>
    <app-shared-subscribe-form [messageBoxIsActive]="messageBoxIsActive"></app-shared-subscribe-form>
</div>
<app-shared-footer></app-shared-footer>
