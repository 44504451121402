import { Injectable } from "@angular/core";
import { BookBaseService } from "./book-base.service";
import { InfiniteScrollResult } from "src/app/types/infinite-scroll-result.type";
import { ListFeaturedBooksRequest } from "src/app/types/list-featured-books-request.type";
import { ListNewBooksRequest } from "src/app/types/list-new-books-request.type";
import { ListBooksScrollRequest } from "src/app/types/list-books-scroll-request.type";

@Injectable({
    providedIn: "root"
})

export class BooksService extends BookBaseService {
    public async list(params: ListBooksScrollRequest): Promise<InfiniteScrollResult> {
        return await this.get("/list", params, false)
    }

    public async listFeatured(params: ListFeaturedBooksRequest): Promise<InfiniteScrollResult> {
        return await this.get("/list/featured", params, false)
    }

    public async listNew(params: ListNewBooksRequest): Promise<InfiniteScrollResult> {
        return await this.get("/list/new", params, false)
    }
}
