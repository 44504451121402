import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookComponent } from './book.component';
import { BookResolver } from "../resolvers/book.resolver";

const routes: Routes = [
    {
        path: "book/:slug",
        component: BookComponent,
        resolve: {
            book: BookResolver
        },
        children: [
            {
                path: "",
                component: BookComponent,
                pathMatch: "full"
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [BookResolver]
})

export class BookRoutingModule { }
