<div id="shared-footer">
    <div class="content">
        <div class="top">
            <div class="right">
                <h4>Links</h4>
                <ul>
                    <li routerLink="/privacy-policy">Privacy policy</li>
                    <li routerLink="/terms-and-conditions">Terms and conditions</li>
                </ul>
                <h4>Our Books</h4>
                <ul>
                    <li *ngFor="let book of books">
                        <a href="/book/{{ book.baseInformations.slug }}">{{ book.baseInformations.title }}</a>
                    </li>
                </ul>
            </div>
            <div class="left">
                <h4>Contacts</h4>
                <a href="mailto:info@coloringbookempire.com">info@coloringbookempire.com</a>
                <h4>Follow us</h4>
                <ul>
                    <li>
                        <a target="_blank" href="https://www.facebook.com/profile.php?id=61550784835984">
                            Facebook
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="bottom">
            <div>Copyright &copy; 2023 All rights reserved.</div>
        </div>
    </div>
</div>
