import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { PaymentsService } from "src/app/services/payment/payments.service";
import { Payment } from "src/app/types/payment.type";

@Component({
    selector: "app-account-payments",
    templateUrl: "./account-payments.component.html",
    styleUrls: ["./account-payments.component.scss"]
})

export class AccountPaymentsComponent {
    private paymentsLoaderIsActive: boolean = true;

    private hidePaymentsLoader() {
        this.paymentsLoaderIsActive = false;
    }

    public payments: Payment[] = [];

    public constructor(
        private paymentsService: PaymentsService,
        private titleService: Title
    ) { }

    public getPaymentsLoaderState(): boolean {
        return this.paymentsLoaderIsActive;
    }

    public async ngOnInit() {
        this.titleService.setTitle("Account payments | Coloring book empire");

        this.payments = (await this.paymentsService.list()).data;

        this.hidePaymentsLoader();
    }
}