<div class="forgot-password-form">
    <div class="content">
        <h1>Forgot Password</h1>
        <div *ngIf="
            getForgotPasswordFormState();
            then showForgotPasswordForm
            else showForgotpasswordSubmitForm">
        </div>
        <ng-template #showForgotPasswordForm>
            <form [formGroup]="forgotPasswordForm">
                <div class="info">Enter your email address in the field below and you will be sent an email with a code that will allow you to set a new password.</div>
                <fieldset>
                    <input type="text" placeholder="Email" formControlName="email" />
                    <div *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                        <div class="error" *ngIf="email?.errors?.['required']">
                            Email is required.
                        </div>
                        <div class="error" *ngIf="email?.errors?.['email']">
                            Invalid email.
                        </div>
                    </div>
                </fieldset>
                <button
                    class="btn--green"
                    [disabled]="getForgotPasswordButtonLoaderState()"
                    (click)="forgotPasswordButtonClick()">
                    <div *ngIf="
                        getForgotPasswordButtonLoaderState();
                        then showForgotPasswordButtonLoader;
                        else hideForgotPasswordButtonLoader">
                    </div>
                    <ng-template #showForgotPasswordButtonLoader>
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </ng-template>
                    <ng-template #hideForgotPasswordButtonLoader>
                        Send
                    </ng-template>
                </button>
            </form>
        </ng-template>
        <ng-template #showForgotpasswordSubmitForm>
            <div></div>
            <form [formGroup]="forgotPasswordSubmitForm">
                <fieldset>
                    <input type="text" placeholder="Code" formControlName="code" />
                    <div *ngIf="code?.invalid && (code?.dirty || code?.touched)">
                        <div class="error" *ngIf="code?.errors?.['required']">
                            Email is required.
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <input type="password" placeholder="Password" formControlName="password" />
                    <div *ngIf="password?.invalid && (password?.dirty || password?.touched)">
                        <div class="error" *ngIf="password?.errors?.['required']">
                            Password is required.
                        </div>
                        <div class="error" *ngIf="password?.errors?.['pattern']">
                            The password must be at least 8 characters and contain at least one uppercase, one lowercase one symbol and a number.
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <input type="password" placeholder="Confirm password" formControlName="confirmPassword" />
                    <div *ngIf="confirmPassword?.invalid && (confirmPassword?.dirty || confirmPassword?.touched)">
                        <div class="error" *ngIf="confirmPassword?.errors?.['required']">
                            Confirm password is required.
                        </div>
                        <div class="error" *ngIf="confirmPassword?.errors?.['pattern']">
                            The password must be at least 8 characters and contain at least one uppercase, one lowercase one symbol and a number.
                        </div>
                    </div>
                </fieldset>
                <button
                    class="btn--green"
                    [disabled]="getForgotPasswordSubmitButtonLoaderState()"
                    (click)="forgotPasswordSubmitButtonClick()">
                    <div *ngIf="
                        getForgotPasswordSubmitButtonLoaderState();
                        then showForgotPasswordSubmitButtonLoader;
                        else hideForgotPasswordSubmitButtonLoader">
                    </div>
                    <ng-template #showForgotPasswordSubmitButtonLoader>
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </ng-template>
                    <ng-template #hideForgotPasswordSubmitButtonLoader>
                        Change password
                    </ng-template>
                </button>
            </form>
        </ng-template>
    </div>
</div>
