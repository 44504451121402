import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogsErrorComponent } from "src/app/dialogs/dialogs-error/dialogs-error.component";
import { UtilsBaseService } from "./utils-base.service";

@Injectable({
    providedIn: "root"
})

export class UtilsDialogsService extends UtilsBaseService {
    public constructor(
        private dialog: MatDialog
    ) {
        super()
    }

    public showError(error: string) {
        this.dialog.open(DialogsErrorComponent, {
            data: {
                message: error
            },
            width: "512px"
        })
    }
}