import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ThankYouPageComponent } from "./thank-you-page.component";

const routes: Routes = [
    {
        path: "thank-you-page",
        component: ThankYouPageComponent,
        children: [
            {
                path: "",
                component: ThankYouPageComponent,
                pathMatch: "full"
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ThankYouPageRoutingModule { }
