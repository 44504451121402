import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { FormAuthService } from "src/app/services/form/form-auth.service";
import { UtilsDialogsService } from "src/app/services/utils/utils-dialogs.service";

@Component({
    selector: "app-auth-signup",
    templateUrl: "./auth-signup.component.html",
    styleUrls: ["./auth-signup.component.scss"]
})

export class AuthSignupComponent {
    private signupButtonLoaderIsActive: boolean = false;
    private showSignupPassword: boolean = false;

    private showSignupButtonLoader() {
        this.signupButtonLoaderIsActive = true;
    }

    private hideSignupButtonLoader() {
        this.signupButtonLoaderIsActive = false;
    }

    public signupForm: FormGroup;

    public constructor(
        private formAuthService: FormAuthService,
        private auth: AuthService,
        private titleService: Title,
        private metaService: Meta,
        private utilsDialogsService: UtilsDialogsService,
        private router: Router
    ) {
        this.signupForm = this.formAuthService.signup;
    }

    public ngOnInit() {
        this.signupForm.reset();

        this.titleService.setTitle("Signup | Coloring book empire");

        this.metaService.updateTag({
            name: "keywords",
            content: ""
        })

        this.metaService.updateTag({
            name: "description",
            content: ""
        })
    }

    public get firstname() {
        return this.signupForm.get("firstname");
    }

    public get lastname() {
        return this.signupForm.get("lastname");
    }

    public get email() {
        return this.signupForm.get("email");
    }

    public get password() {
        return this.signupForm.get("password");
    }

    public get confirmPassword() {
        return this.signupForm.get("confirmPassword");
    }

    public getSignupButtonLoaderState(): boolean {
        return this.signupButtonLoaderIsActive;
    }

    public showPasswordTypeToggle() {
        this.showSignupPassword = !this.showSignupPassword;
    }

    public getSignupPasswordType(): "text" | "password" {
        if (this.showSignupPassword) {
            return "text";
        }

        return "password";
    }

    public async signUpButtonClick() {
        if ( ! this.formAuthService.validateForm(this.signupForm)) {
            return;
        }
    
        const user = {
            username: this.email?.value,
            password: this.password?.value,
            attributes: {
                "custom:firstname": this.firstname?.value,
                "custom:lastname": this.lastname?.value
            }
        }

        this.showSignupButtonLoader();

        const data = await this.auth.signUp(user);

        if (data.response.code === "UsernameExistsException") {
            this.hideSignupButtonLoader();

            this.utilsDialogsService.showError("A user already exists with this email.");

            return;
        }

        this.router.navigate(["/auth/signup-confirmation"]);
    }
}
